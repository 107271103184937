<template>
  <div class="billingSnap">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">Search Adhoc Charges</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <v-card :loading="loading" class="mb-4">
        <v-form class="ma-2" @submit.prevent>
          <v-container>
            <v-row>
              <company-select
                @chosen="setCompany"
                v-model="companySelected"
                :colWidth="3"
              ></company-select>

              <v-col cols="3">
                <v-text-field
                  label="search reference"
                  v-model="searchParam"
                  dense
                  outlined
                  @keyup.enter="getData()"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-btn
                  :loading="loading"
                  :disabled="loading || !searchParam"
                  color="primary"
                  @click="getData()"
                  >Search Adhoc Charges</v-btn
                >
              </v-col>
              <v-col cols="3" v-if="companySelected && companySelected.code">
                <v-btn
                  :loading="loading"
                  :disabled="loading || !searchParam"
                  color="primary"
                  @click="getData(companySelected.id)"
                  >Search For {{ companySelected.code }}</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
      <div class="billingTable" >
        <v-card v-if="data.length > 0 ">
          <v-card-title>
            Adhoc Charges with Reference: {{ loadedSearchParam }}
          </v-card-title>
          <v-row class="ma-1">
            <v-col cols="6">
              <ExportButtons
                :headers="headers"
                :data="data"
                :title="'adhoccharges'"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <v-data-table
            :headers="headers"
            :items="data"
            :items-per-page="5"
            :search="search"
          >
            <template v-slot:item.first_name="{ item }">{{
              (item.first_name ? item.first_name : "") +
              " " +
              (item.last_name ? item.last_name : "")
            }}</template>
          </v-data-table>
        </v-card>
        <v-card v-if="data.length === 0 && loaded">
             <v-card-title>
            No Adhoc Charges found with reference: {{ loadedSearchParam }}
          </v-card-title>
            
        </v-card>
        <v-snackbar v-model="snackbar" :timeout="5000">
          {{ text }}
          <v-btn color="blue" text @click="snackbar = false"></v-btn>
        </v-snackbar>
      </div>
    </div>
  </div>
</template>
<script>
import AdhocCharges from "@/services/AdhocCharges.js";
import CompanySelect from "@/components/forms/CompanySelect";
import ExportButtons from "@/components/ExportButtons";

export default {
  components: {
    ExportButtons,
    CompanySelect,
  },
  data() {
    return {
      // Snackbar
      snackbar: false,
      text: "",

      // Search
      search: "",
      // Button Loading
      loading: false,

      // Table
      data: [],
      headers: [
        { text: "Company Code", value: "company_code" },
        { text: "Charge Date", value: "charge_date" },
        { text: "charge_type", value: "charge_type" },
        { text: "Reference", value: "reference" },
        { text: "client_reference", value: "client_reference" },
        { text: "total_charge", value: "total_charge" },
        { text: "Charged Added By", value: "first_name" },
      ],

      searchParam: "",
      loadedSearchParam: "",
      companySelected: {},
      loaded: false,
    };
  },
  methods: {
    setCompany(company) {
      this.companySelected = company;
    },
    clearFields() {
      this.loadedSearchParam = "";
      this.data = [];
      this.loaded = false
    },
    async getData(companyId) {
      this.loading = true;
      if (!this.searchParam) return;
      let params = { searchParam: this.searchParam };
      if (companyId) {
        params.companyId = companyId;
      }
      this.clearFields();
      AdhocCharges.searchAdhocCharges(params)
        .then(
          ((data) => {
            this.$set(this, "data", data);
            this.loadedSearchParam = this.searchParam;
            this.loading = false;
            this.snackbar = true;
            this.text = "Successfully Loaded Data";
            this.loaded = true
          }).bind(this)
        )
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
          return;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.org-description {
  margin-top: 50px;
}
label {
  display: flex;
  align-items: center;
}
.button-end {
  align-self: flex-end;
}
</style>